import React from "react";
import styled from "styled-components";
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const Card = styled.div`
  display: flex;
  align-items: flex-end;
  flex-flow: column;
  margin: 12px;
  width: 325px;
  min-width: 280px;
  height: 400px;
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
  border-radius: 24px;
  overflow: hidden;

  &:hover{
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  }
  a{
    height: 100%;
    display: flex;
    align-items: flex-start;
    flex-flow: column;  
  }
  .image {
    display: flex;
    align-items: flex-end;
    height: 50%;
    width: 100%;
  }

  .content {
    padding: 12px;
    height: 50%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    color: #ffffff;

    p {
      line-height: 1rem;
      font-size: 0.85rem;
      font-weight: 400;
    }
  }
  
  h3 {
    font-size: 1.1rem;
    font-weight: 700;
    opacity: 1;
  }

  .author {
    display: flex;
    flew-flow: row;
    p{
      font-size: 0.8rem;
      font-weight: 700;
      margin-right: 12px;
    }
    margin: 0.2rem 0rem;
  }

  p {
    font-size: 0.72rem;
    font-weight: 600;
    margin-bottom: 0;
  }
  
  .cta{
    align-self: flex-end;
    font-weight: 700;
    font-size: 0.9rem;
    margin-top: auto;
  }

  @media screen and (max-width: 750px) {
    width: 100%;
    height: 150px;
    box-shadow: 0 0px 0px rgba(0,0,0,0.16), 0 0px 0px rgba(0,0,0,0.23);

    a{
      width: 100%;
      flex-flow: row;
      .image, .content{
        height: 100%;
      }
      .image{
        width: 35%;
      }
      .content{
        width: 65%;
        justify-content: flex-start;
        padding: 4px;
        h3{
          order: 1;
          font-size: 14px;
        }
        .description{
          max-height: 80%;
          overflow: hidden;
          order: 2;
          margin: 0px 0px 8px 0px;
        }
        .author{
          margin-top: auto;
          order: 3;
          flex-flow: column-reverse;
          p{
            font-size: 0.7rem;
            font-weight: 700;
          }
        }
        .cta {
          display: none;
        }
      }
    }
  }

  @media screen and (max-width: 500px) {
    height: 170px;
    .content{
      .description{
        font-size: 0.75rem;
        font-weight: 400;
      }
    }
  }


`

const BlogPostCard = ({ id, title, author, date, readTime, description, excerpt, image, slug }) => {
  const heroImage = getImage(image);

    return (
        <Card key={id}>
            <a href={`${slug}`} className="CleanLink">
            <GatsbyImage className="image" image={heroImage} alt={description}/>
            <div className="content purpleTextBackground">
                <h3>{title}</h3>
                <div className="author">
                  <p>By {author}</p>
                  <p>{date} &bull; {readTime}</p>
                </div>
                <p className="description">{description}</p>
                <p className="cta">Read more</p>
            </div>
            </a>
        </Card>
    )
}

export default BlogPostCard;