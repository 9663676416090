import React from "react"
import PropTypes from "prop-types"
import Layout from "../components/layout"
import styled from "styled-components"
import { Link, graphql } from "gatsby"
import BlogPostCard from "../components/blog/blogPostCard"
import Newslettersignup from "../components/Newslettersignup"
import { BrandColours } from "../utils/colours";

const BodyWrapper = styled.div`
  margin: 60px auto;
  max-width: 1000px;
  a {
    text-decoration: none;
  }

  h1{
    font-weight: 700;
    font-size: 3.5rem;
    line-height: 1;
    color: ${BrandColours.purple.base};

  }

  .AllTags {
    background-color:${BrandColours.purple.base};
    color: white;;
    text-decoration: none;
    font-size: 1.2rem;
    font-weight: 800;
    border-radius: 3px;
    padding: 5px 10px;
    display: inline-block;
    margin: 0 10px 10px 0;
  
  }
`;


const SpacerDiv = styled.div`
min-width: 20px;
margin:0px;
height:auto;
`;

const PostsContainer = styled.div`
    display: flex;
    flex-flow: row wrap;
    overflow-x: auto;
    padding: 0 20px 20px 20px;

`;


const Tags = ({ pageContext, data }) => {
  const { tag } = pageContext;
  const { edges, totalCount } = data.allMdx;
  const tagHeader = `${totalCount} post${
    totalCount === 1 ? "" : "s"
  } tagged with "${tag}"`

  return (
    <Layout>
      <BodyWrapper>
      <h1>{tagHeader}</h1>
      <PostsContainer>
        {edges.map(({ node }) => {
          return (
            <BlogPostCard
              id={node.id} 
              title={node.frontmatter.title} 
              author={node.frontmatter.author} 
              date={node.frontmatter.date}
              readTime={node.frontmatter.readTime}
              description={node.frontmatter.description} 
              excerpt={node.frontmatter.excerpt} 
              image={node.frontmatter.contentImageUrl} 
              slug={node.fields.slug}
              />
          )
        })}
        <SpacerDiv> </SpacerDiv>
      </PostsContainer>
      {/*
              This links to a page that does not yet exist.
              You'll come back to it!
            */}
      <Link className="AllTags" to="/tags">All tags</Link>
      <Newslettersignup/>
      </BodyWrapper>
    </Layout>
  )
}

Tags.propTypes = {
  pageContext: PropTypes.shape({
    tag: PropTypes.string.isRequired,
  }),
  data: PropTypes.shape({
    allMdx: PropTypes.shape({
      totalCount: PropTypes.number.isRequired,
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            frontmatter: PropTypes.shape({
              title: PropTypes.string.isRequired,
            }),
            fields: PropTypes.shape({
              slug: PropTypes.string.isRequired,
            }),
          }),
        }).isRequired
      ),
    }),
  }),
}

export default Tags

export const pageQuery = graphql`
  query($tag: String) {
    allMdx(
      limit: 2000
      sort: {fields: [frontmatter___date], order: DESC}
      filter: {frontmatter: {tags: {in: [$tag]}}}
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            description
            contentImageUrl {
              childImageSharp {
                id
                gatsbyImageData(
                  webpOptions: {quality: 70}
                  layout: FULL_WIDTH
                  blurredOptions: {toFormat: WEBP, width: 10}
                  breakpoints: 1920
                )
              }
            }
            author
            readTime
            tags
          }
        }
      }
    }
  }
`